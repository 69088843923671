const iconBaseUrl = 'https://api.yoboo.health/file/';

export const availablePharmacies = [
  {
    display_name: "",
    uniq_id: "extra",
  },
  {
    display_name: "",
    uniq_id: "DNA",
  },
  {
    display_name: "",
    uniq_id: "Calysta",
  },
  {
    display_name: "Apotheek Lisp",
    uniq_id: "Lisp",
    icon: "https://images.squarespace-cdn.com/content/v1/620273827215974b26762e2e/a505a951-be7f-4414-ab37-b561c0f4b5f1/LIS-logo-woordmerk.png?format=1500w",
  },
  {
    display_name: "Apotheek Noorderlaan",
    uniq_id: "dlhxpgkveu",
    icon: `${iconBaseUrl}public/661d220ee74cf36805bc439c/ApotheekNoorderlaan.png`
  },
  {
    display_name: "teva",
    uniq_id: "teva",
    icon: `/assets/images/teva.png`,
  },
  {
    display_name: "",
    uniq_id: "general",
  },
  {
    display_name: "Tomorrowland",
    uniq_id: "lovetomorrow",
    icon: `${iconBaseUrl}public/669fcfb79bbd0e609ff14fa5/Love Tomorrrow - circle.png`
  },
  {
    display_name: "Twikit NV",
    uniq_id: "4xgdheh5o8",
  },
  {
    display_name: "Apotheek Melotte",
    uniq_id: "6io15mvv9a",
    icon: `${iconBaseUrl}public/661d2a38617f5aa9445407fc/Melotte.png`
  },
  {
    display_name: "Apotheek Van Hoecke",
    uniq_id: "cfnc9m7p2h",
    icon: `${iconBaseUrl}public/661d2a54617f5aa9445407fd/Logo-Vanhoecke.png`
  },
  {
    display_name: "Proctifin",
    uniq_id: "52mcgaoazc",
  },
  {
    display_name: "Bank J.Van Breda",
    uniq_id: "nk9sm5nnmp",
  },
  {
    display_name: "ZDP",
    uniq_id: "9rvl29b596",
  },
  {
    display_name: "Intake bv (yoboo)",
    uniq_id: "ee1wzuw2sa",
  },
  {
    display_name: "Z+ Center",
    uniq_id: "8lutha7242",
    icon: `${iconBaseUrl}public/661d23bfe74cf36805bc439d/Z-pharma.png`
  },
  {
    display_name: "Apotheek Antverpia Mortsel",
    uniq_id: "4p8cw4zl5s",
  },
  {
    display_name: "Apotheek Elsenborgh",
    uniq_id: "2tcaiyfdar",
    icon: `${iconBaseUrl}public/661d2a0b617f5aa9445407fa/Logo-Elsenborgh.png`
  },
  {
    display_name: "Apotheek Arena",
    uniq_id: "tf3omo42vf",
    icon: `${iconBaseUrl}public/661d29c5617f5aa9445407f9/Arena.png`
  },
  {
    display_name: "Apotheek Minnaert",
    uniq_id: "8u2e3s9vdu",
  },
  {
    display_name: "Apotheek Luyten",
    uniq_id: "ye7v6fu3wn",
    icon: `${iconBaseUrl}public/661d2b70617f5aa944540800/Logo-Luyten.png`
  },
  {
    display_name: "Apotheek Julie Borghijs",
    uniq_id: "2ma9oj2ttp",
    icon: `${iconBaseUrl}public/661d2a24617f5aa9445407fb/Logo- Borghijs.png`
  },
  {
    display_name: "Apotheek Véronique Janssens",
    uniq_id: "4x7grjt5lb",
  },
  {
    display_name: "Apotheek Dok Noord",
    uniq_id: "hgd2oz65ek",
  },
  {
    display_name: "Apotheek Boone",
    uniq_id: "bwzfgk2j3n",
  },
  {
    display_name: "Apotheek Verschaeve",
    uniq_id: "7fxpbjtggr",
  },
  {
    display_name: "Pharma Noord",
    uniq_id: "fea7ckhofe",
  },
  {
    display_name: "Apotheek Swijzen",
    uniq_id: "32sv685j11",
  },
  {
    display_name: "Apotheek Panorama",
    uniq_id: "m2jkwcnfcp",
  },
  {
    display_name: "Apotheek De Schrijver",
    uniq_id: "pk3l64w44l",
  },
  {
    display_name: "Apotheek Hendrickx",
    uniq_id: "1ynz9gwa5o",
    icon: `${iconBaseUrl}public/661d3109617f5aa94454080c/Logo Hendrickx.png`,
  },
  {
    display_name: "Devos Apotheek",
    uniq_id: "p75ve5zo1g",
  },
  {
    display_name: "Sollie Apothekers",
    uniq_id: "37q5ixt4m7",
  },
  {
    display_name: "Apotheek Lieten",
    uniq_id: "9153x5k9pf",
  },
  {
    display_name: "Apotheek Poppe",
    uniq_id: "pey5pgcrzo",
  },
  {
    display_name: "Apotheek Bollengier",
    uniq_id: "qumgcgw7bj",
  },
  {
    display_name: "Pharmacie de l'Arbre Ballon",
    uniq_id: "jxzt5xg8s6",
  },
  {
    display_name: "Aa Pharma Turnhout",
    uniq_id: "k5iwvbnla4",
  },
  {
    display_name: "Apotheek Pasmans",
    uniq_id: "3kevevpq2v",
    icon: `${iconBaseUrl}public/661d2b94617f5aa944540801/Logo-Pasmans.png`,
  },
  {
    display_name: "Aa Pharma Oosthoven",
    uniq_id: "sdfgvl8oei",
  },
  {
    display_name: "Apotheek yoboo",
    uniq_id: "fg6kveiiqr",
  },
  {
    display_name: "Apotheek HelloHealth",
    uniq_id: "jr3f6kecox",
    icon: `${iconBaseUrl}public/660543ee7e076d9e96518661/profile1711621103373.png`,
  },
  {
    display_name: "Apotheek Braeckevelt-Lafaut",
    uniq_id: "cmsr7eyaw8",
    icon: `${iconBaseUrl}public/661d2c3e617f5aa944540805/braeckevelt.png`,
  },
  {
    display_name: "Apotheek Lozie",
    uniq_id: "8w14vuih3m",
    icon: `${iconBaseUrl}public/661d2c5f617f5aa944540806/Logo-Lozie.png`,
  },
  {
    display_name: "Demo Business",
    uniq_id: "qxzvns2pmw",
  },
  {
    display_name: "Apotheek Lisp",
    uniq_id: "5arlbvyc7x",
    icon: `${iconBaseUrl}public/661d2998617f5aa9445407f8/Lisp.png`,
  },
  {
    display_name: "Apotheek Spoele",
    uniq_id: "ydw9mhz8cr",
    icon: `${iconBaseUrl}public/661d2c13617f5aa944540804/Logo-Spoele.png`,
  },
  {
    display_name: "Apotheek Vandebroek",
    uniq_id: "nlvo4emy5n",
    icon: `${iconBaseUrl}public/661d2bcd617f5aa944540803/logo-vandenbroek.png`,
  },
  {
    display_name: "Apotheek De Vos",
    uniq_id: "iyqx4hl67u",
    icon: `${iconBaseUrl}public/661d2bb7617f5aa944540802/Logo-Devos.png`,
  },
  {
    display_name: "Pharmacie Benit",
    uniq_id: "u4h8r9nijj",
    icon: `${iconBaseUrl}public/661d2d50617f5aa944540807/Apotheek Benit.png`,
  },
  {
    display_name: "Pharmacie Dehaut",
    uniq_id: "j1y7c1q38k",
    icon: `${iconBaseUrl}public/661d2d76617f5aa944540808/Apotheek Dehaut.png`,
  },
  {
    display_name: "Pharmacie des Cableries",
    uniq_id: "dwb7xnwabr",
    icon: `${iconBaseUrl}public/661d2d8d617f5aa944540809/Apotheek des Cableries.png`,
  },
  {
    display_name: "Pharmacie de Monsville",
    uniq_id: "z3ls1ojd93",
    icon: `${iconBaseUrl}public/661d2da3617f5aa94454080a/Apotheek de Monsville.png`,
  },
  {
    display_name: "Nhe consulting",
    uniq_id: "q8onyjvb8l",
  },
];
