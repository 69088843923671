import { FC, useEffect, useRef, useState } from 'react';
import Navbar from '../../components/Navbar';
import { Trans, useTranslation } from 'react-i18next';
import WelcomeImage from '../../assets/images/welcome-image.png';
import WelcomeFace from '../../assets/images/welcome-face.png';
import Mirror from '../../assets/images/Mirror.png';
import styles from './styles.module.css';
import { Button } from '../../components/Button';
import { Dialog } from '@mui/material';
import { Link } from 'react-router-dom';
import { useQuery } from '../../hooks';
import { availablePharmacies } from '../../availablePharmacies';
import { availableEvents } from '../../availableEvents';
import { ArrowForward, Clock } from '../../assets/svg';
import { CurvedUnderline } from '../../assets/svg/CurvedUnderline';
import Footer from '../../components/Footer';

export const Welcome: FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { i18n, t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const query = useQuery();

    // Set the default image and define a state for it
    const [currentImage, setCurrentImage] = useState(WelcomeImage);
    
    // Ref to track the width of the last line
    const lastWordsRef = useRef<HTMLSpanElement>(null);
    const [lastWordsWidth, setLastWordsWidth] = useState<number>(0);
    const translatedText = t('welcome.title').split('<br />');

    const updateWidth = () => {
        if (lastWordsRef.current) {
            const rect = lastWordsRef.current.getBoundingClientRect();
            setLastWordsWidth(rect.width);
        }
    };

    // Listen for screen size changes to update the image
    useEffect(() => {
        const updateImage = () => {
            if (window.innerWidth >= 1000 && window.innerHeight < 1600) { // The size of the desktop/laptop screen
                setCurrentImage(Mirror);
            } else if (window.innerWidth >= 1000 && window.innerHeight >= 1600) { // The size of the yoboo tablet screen
                setCurrentImage(WelcomeImage);
            } else { // The size of the mobile screen
                setCurrentImage("");
            }
        };

        updateImage(); // Initial check
        window.addEventListener('resize', updateImage); // Listen for resize events

        return () => window.removeEventListener('resize', updateImage); // Cleanup on unmount
    }, []);

    useEffect(() => {
        setTimeout(() => {
            updateWidth();
        }, 200);
    }, []);

    useEffect(() => {
        updateWidth();
    }, [translatedText]);

    const handleModal = () => {
        setIsOpen(!isOpen);
    };

    const getPharmacy = (pharmacy: string) => availablePharmacies.find((e) => e.uniq_id === pharmacy);
    const getEvent = (event: string) => availableEvents.find((e) => e.utm_campaign === event);

    useEffect(() => {
        const source = query.get('source');

        if (source) {
            localStorage.setItem('source', source);
            localStorage.setItem('resultsSent', 'true');
            return;
        }

        if (
            localStorage.getItem('pharmacyId') !== 'teva' &&
            localStorage.getItem('pharmacyId') !== 'general'
        ) {
            localStorage.removeItem('resultsSent');
        } else {
            localStorage.setItem('resultsSent', 'true');
        }

        const utm_source = query.get('utm_source');
        if (utm_source) return;

        const pharmacy = query.get('pharmacy');
        const event = query.get('event');
        const utm_source_local = localStorage.getItem('utm_source');
        const utm_medium_local = localStorage.getItem('utm_medium');
        const utm_campaign_local = localStorage.getItem('utm_campaign');

        if (pharmacy) {
            if (pharmacy === 'none') {
                localStorage.removeItem('pharmacy');
                localStorage.removeItem('pharmacyIcon');
                localStorage.removeItem('pharmacyId');
                window.location.href = '/';
                return;
            }

            const selectedPharmacy = pharmacy === 'Arena' ? getPharmacy('tf3omo42vf') : getPharmacy(pharmacy);
            if (selectedPharmacy) {
                localStorage.setItem('pharmacy', selectedPharmacy.display_name);
                selectedPharmacy.icon
                    ? localStorage.setItem('pharmacyIcon', selectedPharmacy.icon)
                    : localStorage.removeItem('pharmacyIcon');
                localStorage.setItem('pharmacyId', selectedPharmacy.uniq_id);
            }

            window.location.href = '/';
        } else if (event) {
            if (event === 'none') {
                localStorage.removeItem('utm_source');
                localStorage.removeItem('utm_medium');
                localStorage.removeItem('utm_campaign');
                window.location.href = '/';
                return;
            }

            const selectedEvent = getEvent(event);
            if (selectedEvent) {
                localStorage.setItem('utm_source', selectedEvent.utm_source);
                localStorage.setItem('utm_medium', selectedEvent.utm_medium);
                localStorage.setItem('utm_campaign', selectedEvent.utm_campaign);
                window.location.href = `/?utm_source=${selectedEvent.utm_source}&utm_medium=${selectedEvent.utm_medium}&utm_campaign=${selectedEvent.utm_campaign}`;
                return;
            }
        }

        if (utm_source_local && utm_medium_local && utm_campaign_local) {
            window.location.href = `/?utm_source=${utm_source_local}&utm_medium=${utm_medium_local}&utm_campaign=${utm_campaign_local}`;
        }
    }, [query]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={styles.pageWrapper}>
            <Dialog
                open={isOpen}
                onClose={handleModal}
                classes={{ paper: styles.modalContainer, root: styles.modalRoot }}
            >
                <div className={styles.modalHeaderContainer}>
                    <span>{t('welcome.infoButton')}</span>
                    <Clock width={window.innerWidth >= 1000 ? '30px' : '25px'} onClick={handleModal} />
                </div>
                <div className={styles.modalContentContainer}>
                    <video className={styles.video} controls id="explainVideo">
                        <source src="https://a.storyblok.com/f/306973/x/b608f5d28b/scan-introduction.mp4" type="video/mp4" />
                    </video>
                </div>
            </Dialog>
            <Navbar isResults={false} />
            <div className={styles.welcomeImageContainer}>
                {currentImage !== "" && <img className={styles.welcomeImage} src={currentImage} alt="Welcome" />}

                <div className={styles.contentContainer}>
                    {currentImage === "" && <img className={styles.welcomeFace} src={WelcomeFace} alt="Smile" />}
                    <h1 className={styles.title}>
                        <span className={styles.textWithUnderline}>
                            <span style={{ zIndex: 5 }}>
                                {translatedText[0]} <br />
                                <span style={{position: "relative", zIndex: 3 }} ref={lastWordsRef}>
                                    <span style={{position: "relative", zIndex: 3}}>{translatedText[1]}</span> 
                                    <CurvedUnderline width={lastWordsWidth} />
                                </span>
                                ?
                            </span>
                        </span>
                    </h1>
                    <div className={styles.textContainer}>
                        <p className={styles.headTitle}>{t('welcome.headTitle')}</p>
                        <p className={styles.description}><Trans i18nKey="welcome.description" /></p>
                        {currentImage !== "" && <img className={styles.welcomeFace} src={WelcomeFace} alt="Smile" />}
                    </div>
                    <Link to="/intro" className={styles.nextBtnContainer}>
                        <Button className={styles.nextBtn} text={t('welcome.startButton')} buttonType="big" icon={currentImage !== "" && <ArrowForward />} />
                    </Link>
                </div>

                <Button text={t('welcome.infoButton')} buttonType="secondary" className={styles.infoButton} onClick={handleModal} />
            </div>
            {(currentImage !== "" || window.innerWidth >= 1000) && <div className={styles.footerContainer}>
                <Footer />
            </div>}
            {currentImage === "" && window.innerWidth < 1000 && <Footer />}
        </div>
    );
};
