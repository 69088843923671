import { FC, MouseEventHandler, useEffect, useState } from 'react';

import { Logo } from '../../assets/svg';
import styles from './styles.module.css';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { MenuIcon } from '../../assets/svg/MenuIcon';
import { ArrowBack } from '../../assets/svg/ArrowBack';
import { t } from 'i18next';

const Navbar: FC<{
    isResults?: boolean,
    onlyLogo?: boolean,
    buttonText?: string,
    backgroundColor?: string,
    onClick?: MouseEventHandler
    children?: React.ReactNode
}> = ({ isResults = false, onlyLogo = false, buttonText = '', backgroundColor = '#fbfbf9', onClick = () => {}, children = null }) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const [navbarBackground, setNavbarBackground] = useState(backgroundColor || '#fbfbf9'); // Initial navbar color

    const goToProfile = () => {
        window.location.href = '/';
    };

    const toggleOverlay = () => {
        window.scrollTo(0, 0);
        setOverlayVisible(!isOverlayVisible);
    };

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Update the navbar background color when overlay visibility changes
    useEffect(() => {
        if (isOverlayVisible) {
            setNavbarBackground('#fbfbf9'); // Set to white when overlay is visible
        } else {
            setNavbarBackground(backgroundColor); // Reset to the original color
        }
    }, [isOverlayVisible, backgroundColor]);

    useEffect(() => {
        if (isOverlayVisible) {
            document.body.style.overflow = 'hidden'; // Disable scrolling
        } else {
            document.body.style.overflow = ''; // Enable scrolling
        }
        return () => {
            document.body.style.overflow = ''; // Reset when unmounted
        };
    }, [isOverlayVisible]);

    return (
        <div className={styles.navbarContainer}>
            <div className={styles.navbar} style={{ backgroundColor: navbarBackground }}>
                <div className={styles.logo_container}>
                    <div onClick={goToProfile}>
                        <Logo width={screenWidth < 1000 ? '89px' : '101px'} fill={navbarBackground === '#27A577' ? '#fbfbf9' : '#27A577'} />
                    </div>
                    {localStorage.getItem('pharmacy') && <span className={styles.and} style={navbarBackground === '#27A577' ? { color: '#fbfbf9' } : {}}>&</span>}
                    {localStorage.getItem('pharmacy') && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        {localStorage.getItem('pharmacyIcon') && <img src={localStorage.getItem('pharmacyIcon') || ''} alt={localStorage.getItem('pharmacy') || ''} width="auto" height="48px" />}
                        <span className={styles.text} style={navbarBackground === '#27A577' ? { color: '#fbfbf9', textDecoration: 'none' } : { textDecoration: 'none' }}>{localStorage.getItem('pharmacy') === 'teva' ? '' : localStorage.getItem('pharmacy')}</span>
                    </div>}
                </div>
                {!onlyLogo && (
                    <div className={styles.actions}>
                        {(isResults && localStorage.getItem('pharmacyId')) && <div onClick={onClick} style={{ cursor: 'pointer' }}>
                            <span className={styles.text}>{buttonText}</span>
                        </div>}
                        {screenWidth < 1000 ? (
                            <MenuIcon className={styles.menuIcon} onClick={toggleOverlay} fill={navbarBackground === '#27A577' ? '#fbfbf9' : '#191717'} />
                        ) : (
                            <LanguageSwitcher textColor={navbarBackground === '#27A577' ? '#fbfbf9' : '#2A2626'} />
                        )}
                    </div>
                )}
            </div>

            {!isOverlayVisible && children}

            {isOverlayVisible && (
                <div className={styles.overlay}>
                    <div className={styles.overlayContent}>
                        <LanguageSwitcher />
                        <div style={{width: '100%', height: '2px', backgroundColor: '#E6E5D1'}}></div>
                        <button className={styles.backButton} onClick={toggleOverlay}>
                            <ArrowBack /> {t('navbar.backButton')}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Navbar;
